// Escape hatch for packages not updated to 'use client' yet
"use client";

import { ErrorProvider } from "@jobmojito/components/providers/ErrorProvider";
import { NextUIProvider } from "@nextui-org/react";
import React from "react";
import { CSPostHogProvider } from "@jobmojito/components/providers/posthog";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <NextUIProvider>
      <ErrorProvider>
        <CSPostHogProvider>
          {children}
        </CSPostHogProvider>
      </ErrorProvider>
    </NextUIProvider>
  );
}
