import { createBrowserClient } from '@supabase/ssr'
import { createClient } from '@supabase/supabase-js'
import { Database } from '@jobmojito/config/types/supabase'
import clientGetCookies from '@jobmojito/components/core/clientCookies'

export default function getClientSupabase() {

  const token = clientGetCookies('interview_token');
  const options = {
    global: {
      headers: { 'Authorization': 'bearer '+ token },
    },
  }
  const supabase = (token?
    createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
      options
    )
    :
    createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
    )
  );
  /*
  const supabase = (token?
      createClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        options
      )
      :createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        ((token!==undefined) ? { cookieOptions: { global: { headers: { 'Authorization': 'bearer '+ token } } } }:undefined)
      ));
  */
  return supabase
}
